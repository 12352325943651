import {
  FaInstagramSquare,
  FaTwitterSquare,
  FaFacebookSquare,
} from "react-icons/fa";
function App() {
  return (
    <div>
      <header>
        <div className="container">
          <h1 className="main">Odam Lviran</h1>
          <div className="flexing title">
            <a href="#">Home</a>
            <a href="#">About</a>
            <a href="#">Contact</a>
          </div>
        </div>
      </header>
      <section className="mainSection">
        <h1 className="entering">Illustrator</h1>
        <div className="wrapper">
          <img src="https://static.wixstatic.com/media/b0a6a8b4cd614ba4b34fef97861ffd94.jpg/v1/fill/w_900,h_900,q_90/b0a6a8b4cd614ba4b34fef97861ffd94.jpg" />
          <img
            src="https://static.wixstatic.com/media/aa0019327c6d4be98654d1f19c65c2d6.jpg/v1/fill/w_900,h_1200,q_90/aa0019327c6d4be98654d1f19c65c2d6.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/560a5087eb674128961368159092afc6.jpg/v1/fill/w_900,h_900,q_90/560a5087eb674128961368159092afc6.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/c92a3add546747bd88fd5038181d8b69.jpg/v1/fill/w_900,h_900,q_90/c92a3add546747bd88fd5038181d8b69.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/1e4f7c8f9c9349018a030f86d2e6be7c.jpg/v1/fill/w_900,h_900,q_90/1e4f7c8f9c9349018a030f86d2e6be7c.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/f9f5a77a1ccc47958da70b18f69f39ea.jpg/v1/fill/w_900,h_900,q_90/f9f5a77a1ccc47958da70b18f69f39ea.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/929ccc31ea0c4a17b2528ab130c678bf.jpg/v1/fill/w_900,h_900,q_90/929ccc31ea0c4a17b2528ab130c678bf.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/aebeb9ca2b2f41328206f6d49ab83dd3.jpg/v1/fill/w_900,h_900,q_90/aebeb9ca2b2f41328206f6d49ab83dd3.jpg"
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/394a147227b149ce9b74508e009f1255.jpg/v1/fill/w_900,h_900,q_90/394a147227b149ce9b74508e009f1255.jpg"
            alt=""
          />
        </div>
      </section>
      <footer>
        <p className="footerTitle flexing">
          © 2023 by Odam Lviran. Proudly created with Wix.com
        </p>
        <div className="flexing icons">
          <FaInstagramSquare />
          <FaTwitterSquare />
          <FaFacebookSquare />
        </div>
      </footer>
    </div>
  );
}

export default App;
